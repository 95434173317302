import axios from "axios";
import { rootURL } from "../utils/utils";

export const getEntities = async (setIsAuthenticated) => {
    try {
        const headers = {
            Authorization: localStorage.getItem("idToken"),
        };
        const response = await axios.get(`${rootURL}/api/entities`, {
            headers,
        });
        return response.data.data;
    } catch (err) {
        if (err.response && err.response.data === "Token Expired") {
            localStorage.clear();
            setIsAuthenticated(false);
            return;
        }
        return err.response;
    }
};
