import React from "react";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { startingTimestamp } from "../utils/utils";

function TimeFrameButton({
    status,
    currentStatus,
    onChange,
    setGranularity,
    setSince,
}) {
    const theme_unselected = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "grey",
                        borderColor: "grey",
                    },
                },
            },
        },
    });

    const theme_selected = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderColor: "grey",
                        fontWeight: "bold",
                    },
                },
            },
        },
    });

    const handleOnClick = () => {
        onChange(status);
        switch (status) {
            case "1M":
                setGranularity(3600);
                setSince(Date.now() / 1000 - 2592000);
                break;
            case "3M":
                setGranularity(3600);
                setSince(Date.now() / 1000 - 7776000);
                break;
            case "YTD":
                setGranularity(86400);
                setSince(Date.parse(new Date().getFullYear()) / 1000 - 28800);
                break;
            case "All":
                setGranularity(86400);
                setSince(startingTimestamp);
                break;
            // default case is 3M
            default:
                setGranularity(3600);
                setSince(Date.now() / 1000 - 7776000);
                break;
        }
    };
    return (
        <ThemeProvider
            theme={currentStatus === status ? theme_selected : theme_unselected}
        >
            <Button onClick={handleOnClick}>{status}</Button>
        </ThemeProvider>
    );
}

export default TimeFrameButton;
