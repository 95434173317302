import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./ChartOptions.css";

function RangeSwitch({ isZerorange, setZerorange }) {
    return (
        <div className="optionsContainer">
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isZerorange}
                            onChange={() => setZerorange(!isZerorange)}
                            label="rangemode"
                        />
                    }
                    label="Start Y-axis at 0"
                />
            </FormGroup>
        </div>
    );
}

export default RangeSwitch;
