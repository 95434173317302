import React from "react";
import "./Footer.css";
import syzcrest_logo from "../assets/syzcrest-logo.png";

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-divider" />
            <div className="footer">
                <img
                    className="footer"
                    src={syzcrest_logo}
                    alt="syzcrest-logo"
                />
            </div>
        </div>
    );
}

export default Footer;
