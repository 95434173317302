import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEntities } from "../api/getEntities";
import { Audio } from "react-loader-spinner";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function ExchangeTable() {
    const nav = useNavigate();
    const fetchStatus = "Fetching Entities";
    const [entities, setEntities] = useState([]);

    const getEntity = async () => {
        try {
            const response = await getEntities();
            setEntities(response);
            console.log(response);
        } catch (e) {
            console.log(e);
        }
    };
    const handleNavigate = (name) => {
        nav(`/chart/${name}`);
    };

    useEffect(() => {
        getEntity();
    }, []);

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Entity Name",
        },
        {
            id: "id",
            numeric: false,
            disablePadding: false,
            label: "Entity ID",
        },
        {
            id: "glassnode",
            numeric: false,
            disablePadding: false,
            label: "Glassnode Name",
        },
        {
            id: "gecko",
            numeric: false,
            disablePadding: false,
            label: "Gecko Name",
        },
        {
            id: "classification",
            numeric: false,
            disablePadding: false,
            label: "Classification",
        },
    ];

    return (
        <div>
            {entities.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650, maxWidth: 1000 }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                        }}
                                        key={headCell.id}
                                        align={
                                            headCell.id === "name"
                                                ? "left"
                                                : "center"
                                        }
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entities &&
                                entities.map((entity) => (
                                    <TableRow
                                        key={entity.entity_id}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                            "&: hover": {
                                                backgroundColor: "#D3D3D3",
                                                cursor: "pointer",
                                            },
                                        }}
                                        onClick={() => {
                                            handleNavigate(
                                                entity.entity_glassnode_name
                                            );
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {entity.entity_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {entity.entity_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {entity.entity_glassnode_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {entity.entity_geckoterminal_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {entity.classification}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div className="fetching">
                    <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="grey"
                        ariaLabel="loading"
                    />
                    <p>{fetchStatus}</p>
                </div>
            )}
        </div>
    );
}

export default ExchangeTable;
