import axios from "axios";
import { rootURL } from "../utils/utils";

export const getExchangeBalances = async (
    exchange,
    asset,
    since,
    granularity
) => {
    try {
        const params = new URLSearchParams({
            exchange: exchange,
            asset: asset,
            since: since,
            granularity: granularity,
        });
        const headers = {
            Authorization: localStorage.getItem("idToken"),
        };

        const response = await axios.get(`${rootURL}/api/exchange_balances`, {
            params,
            headers,
        });
        return response.data.data;
    } catch (err) {
        if (err.response && err.response.data === "Token Expired") {
            localStorage.clear();
            // setIsAuthenticated(false);
            return;
        }
        return err.response;
    }
};
