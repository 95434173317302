import React, { useState, useEffect } from "react";
import "./ChartOptions.css";
import { startingTimestamp } from "../utils/utils";
import TimeFrameButton from "./TimeFrameButton";
import { ButtonGroup } from "@mui/material";

function ChartOptions({ since, setSince, granularity, setGranularity }) {
    const [status, setStatus] = useState("3M");

    const handleRadioChange = (status) => {
        setStatus(status);
    };
    useEffect(() => {
        if (since === startingTimestamp) {
            setStatus("All");
        } else if (
            since ===
            Date.parse(new Date().getFullYear()) / 1000 - 28800
        ) {
            setStatus("YTD");
        }
    }, [since]);
    return (
        <div className="optionsContainer">
            <ButtonGroup size="small">
                <TimeFrameButton
                    status="1M"
                    currentStatus={status}
                    onChange={handleRadioChange}
                    setGranularity={setGranularity}
                    setSince={setSince}
                />
                <TimeFrameButton
                    status="3M"
                    currentStatus={status}
                    onChange={handleRadioChange}
                    setGranularity={setGranularity}
                    setSince={setSince}
                />
                <TimeFrameButton
                    status="YTD"
                    currentStatus={status}
                    onChange={handleRadioChange}
                    setGranularity={setGranularity}
                    setSince={setSince}
                />
                <TimeFrameButton
                    status="All"
                    currentStatus={status}
                    onChange={handleRadioChange}
                    setGranularity={setGranularity}
                    setSince={setSince}
                />
            </ButtonGroup>
        </div>
    );
}

export default ChartOptions;
