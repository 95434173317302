import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Alert from "./components/Alert";
import ExchangeChart from "./components/ExchangeChart";
import Test from "./components/Test";
import ExchangeTable from "./components/ExchangeTable";
import Login from "./components/Login";
import Docs from "./components/Docs";
import { isTokenExpired } from "./utils/utils";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (
            !localStorage.getItem("idToken") ||
            isTokenExpired(localStorage.getItem("idToken"))
        ) {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <Router>
            <div className="App">
                {!isAuthenticated ? (
                    <Login setIsAuthenticated={setIsAuthenticated} />
                ) : (
                    <div>
                        <Header setIsAuthenticated={setIsAuthenticated} />
                        <Routes>
                            <Route path="/" element={<Alert />} exact />
                            <Route path="/test" element={<Test />} exact />
                            <Route path="/docs" element={<Docs />} exact />
                            <Route
                                path="/exchange"
                                element={<ExchangeTable />}
                            />
                            <Route
                                path="/chart/:exchange"
                                element={<ExchangeChart />}
                            />
                        </Routes>
                        <Footer />
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
