import axios from "axios";
import { rootURL } from "../utils/utils";

export const getAlert = async (exclude_tests = true, limit = 1000) => {
    try {
        const params = new URLSearchParams({
            exclude_tests: exclude_tests,
            limit: limit,
        });
        const headers = {
            Authorization: localStorage.getItem("idToken"),
        };

        const response = await axios.get(`${rootURL}/api/alerts`, {
            params,
            headers,
        });
        return response.data.data;
    } catch (err) {
        if (err.response && err.response.data === "Token Expired") {
            localStorage.clear();
            // setIsAuthenticated(false);
            return;
        }
        return err.response;
    }
};
