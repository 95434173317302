import axios from "axios";
import { rootURL } from "../utils/utils";

export const signAlert = async (incident, signer, notes) => {
    try {
        const params = {
            incident: incident,
            signer: signer,
            notes: notes,
        };
        const headers = {
            Authorization: localStorage.getItem("idToken"),
        };
        const response = await axios.put(`${rootURL}/api/sign`, null, {
            params,
            headers,
        });
        return response.data;
    } catch (err) {
        if (err.response && err.response.data === "Token Expired") {
            localStorage.clear();
            // setIsAuthenticated(false);
            return;
        }
        return err.response;
    }
};
