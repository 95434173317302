import React from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        PaperProps={{
            style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
            },
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        alignItems: "center",
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export default function CustomizedMenus({ page, entities }) {
    const nav = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const redirect = (entity) => {
        handleClose();
        nav(`/chart/${entity}`);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                sx={{
                    // marginLeft: 10,
                    lineHeight: 0,
                    fontFamily: "Helvetica",
                    letterSpacing: 0,
                    backgroundColor: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                    paddingBottom: 1,
                    paddingTop: 1.1,
                    paddingLeft: 5,
                    paddingRight: 5,
                    color: "#3aa6e2",
                    "&:hover": {
                        backgroundColor: "white",
                        color: "#3f8ece",
                    },
                }}
            >
                CHARTS
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {entities &&
                    entities.map((entity) => {
                        return (
                            <MenuItem
                                key={entity}
                                onClick={() => {
                                    redirect(entity);
                                }}
                                disableRipple
                            >
                                {entity}
                            </MenuItem>
                        );
                    })}
            </StyledMenu>
        </div>
    );
}
