import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import ocra_icon from "../assets/ocra-logo.png";
import "./Login.css";
import { jwtDecode } from "jwt-decode";

// Google OAuth 2.0: https://github.com/MomenSherif/react-oauth

function Login({ setIsAuthenticated, setUser }) {
    return (
        <div className="login-container">
            <img className="login-ocra-icon" src={ocra_icon} alt="ocra-icon" />

            <GoogleLogin
                onSuccess={(response) => {
                    const id_token = response.credential;
                    const userObject = jwtDecode(response.credential);
                    localStorage.setItem("idToken", id_token);
                    localStorage.setItem("user", userObject.name);
                    setIsAuthenticated(true);
                }}
                onError={() => {
                    console.log("error");
                }}
                size="large"
                shape="pill"
                theme="filled_blue"
                useOneTap
                auto_select
            />
        </div>
    );
}
export default Login;
