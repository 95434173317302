import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { getExchangeBalances } from "../api/getExchangeBalances";
import { Audio } from "react-loader-spinner";
import { filterData, Capitalize } from "../utils/utils";
import "./ExchangeChart.css";
import ChartOptions from "./ChartOptions";
import RangeSwitch from "./RangeSwitch";

function ExchangeChart() {
    const [exchangeBalance, setExchangeBalance] = useState({
        dailyBalance: [],
        hourlyBalance: [],
    });
    const assets = ["BTC", "ETH", "USDT", "USDC"];

    // Default: 3M-hourly data
    const [since, setSince] = useState(Date.now() / 1000 - 7776000);
    const [granularity, setGranularity] = useState(3600);
    const [isZerorange, setZerorange] = useState(true);

    const { exchange } = useParams();
    const getBalancesFromDB = async (exchange, asset, since, granularity) => {
        try {
            var tempGranularity;
            var tempSince;
            if (granularity === 3600) {
                tempGranularity = "1h";
                tempSince = Math.floor(Date.now() / 1000 - 7776000);
            } else {
                tempGranularity = "1d";
                tempSince = null;
            }
            const response = await getExchangeBalances(
                exchange,
                asset,
                tempSince,
                tempGranularity
            );
            return response;
        } catch (e) {
            console.log(e);
        }
    };
    const updateBalances = async () => {
        const promises = assets.map((asset) => {
            return getBalancesFromDB(exchange, asset, since, granularity);
        });

        try {
            const balanceResponses = await Promise.all(promises);
            if (granularity === 3600) {
                const updatedBalance = {
                    ...exchangeBalance,
                    hourlyBalance: [...balanceResponses],
                };
                setExchangeBalance(updatedBalance);
            } else {
                const updatedBalance = {
                    ...exchangeBalance,
                    dailyBalance: [...balanceResponses],
                };
                setExchangeBalance(updatedBalance);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Step 1: Check if exchangeBalance has any data
        if (
            exchangeBalance.hourlyBalance.length > 0 ||
            exchangeBalance.dailyBalance.length > 0
        ) {
            // Step 2: Get exchange of existing data
            var prevExchange;
            if (exchangeBalance.hourlyBalance.length > 0) {
                prevExchange = exchangeBalance.hourlyBalance[0][0].exchange;
            } else if (exchange.dailyBalance.length > 0) {
                prevExchange = exchangeBalance.dailyBalance[0][0].exchange;
            }

            // Step 3: Check if exchange matches
            if (exchange === prevExchange) {
                if (
                    (granularity === 3600 &&
                        (exchangeBalance.hourlyBalance.length === 0 ||
                            exchangeBalance.hourlyBalance[0][0].exchange !==
                                exchange)) ||
                    (granularity === 86400 &&
                        (exchangeBalance.dailyBalance.length === 0 ||
                            exchangeBalance.dailyBalance[0][0].exchange !==
                                exchange))
                ) {
                    updateBalances();
                }
            } else {
                // reset balance
                const balance = {
                    dailyBalance: [],
                    hourlyBalance: [],
                };
                setExchangeBalance(balance);
                setSince(Date.now() / 1000 - 7776000);
                setGranularity(3600);
            }
        } else {
            // Update directly
            updateBalances();
        }
        // eslint-disable-next-line
    }, [since, granularity, exchange, exchangeBalance]);

    return (
        <div>
            {exchangeBalance &&
            ((granularity === 3600 &&
                exchangeBalance.hourlyBalance.length > 0 &&
                exchangeBalance.hourlyBalance[0][0].exchange === exchange) ||
                (granularity === 86400 &&
                    exchangeBalance.dailyBalance.length > 0 &&
                    exchangeBalance.dailyBalance[0][0].exchange ===
                        exchange)) ? (
                <div className="exchangeChart">
                    <h1>{Capitalize(exchange)} Reserves</h1>
                    {getChartData(
                        exchangeBalance,
                        since,
                        granularity,
                        isZerorange
                    )}
                    <ChartOptions
                        since={since}
                        setSince={setSince}
                        granularity={granularity}
                        setGranularity={setGranularity}
                    />
                    <RangeSwitch
                        isZerorange={isZerorange}
                        setZerorange={setZerorange}
                    />
                </div>
            ) : (
                <div className="fetching">
                    <Audio
                        height="80"
                        width="80"
                        radius="9"
                        color="grey"
                        ariaLabel="loading"
                    />
                    <p>Fetching Chart...</p>
                </div>
            )}
        </div>
    );
}

export default ExchangeChart;

function getChartData(exchangeBalance, since, granularity, isZerorange) {
    const filteredData = filterData(exchangeBalance, since, granularity);
    const firstTimestamps = filteredData.map((subArray) =>
        subArray.length > 0 ? new Date(subArray[0]["timestamp"] * 1000) : null
    );
    const validFirstTimestamps = firstTimestamps.filter(
        (date) => date !== null
    );
    const startDate = new Date(
        Math.min(...validFirstTimestamps.map((date) => date.getTime()))
    );
    const lastTimestamps = filteredData.map((subArray) =>
        subArray.length > 0
            ? new Date(subArray[subArray.length - 1]["timestamp"] * 1000)
            : null
    );
    const validLastTimestamps = lastTimestamps.filter((date) => date !== null);
    const endDate = new Date(
        Math.max(...validLastTimestamps.map((date) => date.getTime()))
    );

    let data = filteredData.map((balance) => {
        if (balance.length > 0) {
            const balanceAmounts = balance.map(
                (balanceAmounts) => balanceAmounts["amount"]
            );
            // const percentageChangeBalanceAmounts =
            //     getPercentageChangeArray(balanceAmounts);
            const dates = balance.map(
                (balanceAmounts) => new Date(balanceAmounts["timestamp"] * 1000)
            );

            let asset = "";
            if (balance[0]["asset"]) {
                asset = balance[0]["asset"];
            }
            return {
                x: dates,
                y: balanceAmounts,
                name: asset,
            };
        } else {
            return null;
        }
    });

    data = data.filter((item) => item !== null);

    function calculateDomain() {
        // Calculate the proportion of the plot's width for the chart area
        const chartWidth = 0.84; // Adjust this value as needed (e.g., 0.7, 0.9, etc.)

        // Calculate the domain values based on the chart width and x-axis range
        const domainStart = (1 - chartWidth) / 2;
        const domainEnd = 1 - domainStart;

        return [domainStart, domainEnd];
    }

    const rangemode = isZerorange ? "tozero" : "normal";

    const layout = {
        automargin: true,
        width: 1200,
        height: 500,
        showlegend: true,
        title: {
            text: "The total number of coins",
            font: {
                family: "Helvetica",
            },
            automargin: true,
            pad: {
                t: 0,
                b: 15,
            },
        },
        margin: {
            t: 30,
            b: 0,
            r: 50,
            l: 50,
            pad: 0,
        },
        xaxis: {
            type: "date",
            range: [startDate, endDate],
            domain: calculateDomain(),
            rangeslider: { range: [startDate, endDate] },
        },
        yaxis: {
            automargin: true,
            title: "USDC/USDT",
            tickformat: ",.3~s",
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
        },
        yaxis2: {
            title: {
                text: "BTC",
                standoff: 15,
            },
            tickformat: ",.3~s",
            tickfont: { color: "#FF7F0F" },
            titlefont: { color: "#FF7F0F" },
            side: "right",
            overlaying: "y",
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
        },
        yaxis3: {
            title: "ETH",
            minor_tick: "outside",
            tickformat: ",.3~s",
            tickfont: { color: "#1F77B4" },
            titlefont: { color: "#1F77B4" },
            side: "right",
            anchor: "free",
            position: 0.99,
            overlaying: "y",
            showgrid: false,
            zeroline: false,
            rangemode: rangemode,
        },
        legend: {
            x: 0.35,
            y: 1.08,
            // x: 0.1,
            // y: 1.05,
            orientation: "h",
        },
    };

    const classColors = {
        USDC: "#8B0000", // Red
        USDT: "#967969", // Green
        ETH: "#1F77B4", // Blue
        BTC: "#FF7F0F", // Orange
    };

    const getYAxis = (name) => {
        switch (name) {
            case "BTC":
                return "y2";
            case "ETH":
                return "y3";
            default:
                return "y1";
        }
    };

    const coloredData = data.map((entry) => ({
        ...entry,
        marker: {
            color: entry && classColors[entry.name],
        },
        yaxis: getYAxis(entry.name),
    }));

    return <Plot className="chart" data={coloredData} layout={layout} />;
}
