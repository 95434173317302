import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getEntities } from "../api/getEntities";
import "./Header.css";
import ocra_icon from "../assets/ocra-logo.png";
import CustomizedMenus from "./CustomizedMenus";
import { googleLogout } from "@react-oauth/google";

function Header({ setIsAuthenticated }) {
    const [page, setPage] = useState("/");
    const [entities, setEntities] = useState([]);
    const location = window.location.href;
    const history = useNavigate();

    const handlePageChange = () => {
        if (location.includes("chart")) {
            setPage("chart");
        } else if (location.includes("exchange")) {
            setPage("exchange");
        } else {
            setPage("/");
        }
    };
    const getEntity = async () => {
        try {
            const response = await getEntities(setIsAuthenticated);
            setEntities(response);
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        handlePageChange();
        getEntity();
        // eslint-disable-next-line
    }, [history]);

    return (
        <div>
            <div className="header">
                <Link to="/">
                    <img
                        className="ocra-icon"
                        src={ocra_icon}
                        alt="ocra-icon"
                    />
                </Link>
                <div className="navBar">
                    <Link to="/docs" className="tab">
                        DOCS
                    </Link>
                    <Link to="/" className="tab">
                        ALERTS
                    </Link>
                    <CustomizedMenus page={page} entities={entities} />
                    <div
                        className="logout"
                        onClick={() => {
                            googleLogout();
                            localStorage.clear();
                            setIsAuthenticated(false);
                        }}
                    >
                        LOGOUT
                    </div>
                </div>
            </div>
            <div className="divider" />
        </div>
    );
}

export default Header;
