import "./Docs.css";

function Process() {
    return (
        <div className="alert-page">
            <div className="alert-process">
                <h2>Red Alert Process</h2>
                <h3>Exchange Bank Run Detection</h3>
                <div>
                    <i>
                        A red alert is triggered when the alert system
                        experiences a sudden, anomalous drop in reserves. These
                        must be responded to immediately as they are often
                        precursors to bank runs on exchanges.
                    </i>
                </div>
                <div>
                    <ol type="1">
                        <li>
                            Live monitoring of collateral of exchange
                            counterparties.
                        </li>
                        <li>
                            When collateral starts falling at an exchange beyond
                            a predefined rate, a risk alert is automatically
                            reported to the Crest Risk Team.
                        </li>
                        <li>
                            In addition, for the purpose of visibility,
                            traceability and documentation, an alert email will
                            be sent at the same time to Crest (
                            <a href="mailto:data@crest.fund" className="docs-email">data@crest.fund</a>
                            ) and Syz Capital (
                            <a href="mailto:teamhf.research@syzcapital.com" className="docs-email">
                                teamhf.research@syzcapital.com
                            </a>
                            ) with cc to a list of Interested Parties**.
                            Interested Parties may also monitor the Telegram
                            alerts.
                        </li>
                        <li>
                            The Crest Risk Team monitors this signal in{" "}
                            <b>3 time zones,</b> (HK, Toronto and London), with
                            a minimum of 2 people per time zone on standby.
                        </li>
                        <li>
                            The Crest Risk Team will assess the signal for the
                            correct signature of a probable bank run vs a false
                            alert. A minimum of 2 qualified people in the Crest
                            Risk Team will make the assessment of a "red alert".
                        </li>
                        <li>
                            In a "red alert", instant messaging of the situation
                            at hand in objective data, in chart format, will be
                            sent to fund managers immediately. It is important
                            each fund manager has the data to assess the
                            situation on their own terms and make their own
                            decision.
                        </li>
                        <li>
                            The Crest Risk Team will continuously update the
                            situation at hand to Crest, Syz Capital and
                            Interested Parties via reply-all emails initiated in
                            Step 3. The first reply should always be an
                            immediate "I'm on it" confirmation that the alert
                            has been received by the first member to have
                            received the signal from Crest Risk Team. Follow-on
                            emails will include which funds have been contacted
                            and their status.
                        </li>
                        <li>
                            If any fund manager has not responded within 1hr of
                            the alert, direct voice calls will be made by the
                            Crest Risk Team to the person stationed to receive
                            the "red alert" call. This person will be assigned
                            before the first subscription into a fund.
                        </li>
                        <li>
                            Target response time for this type of risk is: All
                            funds notified <b>within 2 hours.</b>
                        </li>
                        <li>
                            We will run drills 2x per year. Inclusive of
                            downstream fund managers if they confirm they are
                            happy to participate.
                        </li>
                    </ol>
                </div>
            </div>
            <div>
                <h2>Yellow Alert Process</h2>
                <h3>Declining Reserves</h3>
                <div>
                    <i>
                        A yellow alert is triggered when the risk system
                        identifies a slow, gradual decline in reserves. While
                        these are not as urgent, they must still be responded
                        to.
                    </i>
                </div>
                <div>
                    <ol type="1">
                        <li>
                            Crest risk team monitors counterparty exposure
                            within its portfolio.
                        </li>
                        <li>
                            Active monitoring of the counterparty ecosystem.
                        </li>
                        <li>
                            Should concerns be expressed about a counterparty,
                            all other counterparties with dependencies must be
                            highlighted.
                        </li>
                        <li>
                            Fund managers are not contacted for yellow alerts,
                            but the Crest team must keep an eye on the exchange
                            going forward.
                        </li>
                        <li>
                            Yellow alerts should be responded to{" "}
                            <b>within 48 hours.</b>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default Process;
